import { message } from "antd";
import { downloadPDF, token } from "../../../../../config/globleData";
import { api } from "../../../../../config/api";
import { Link } from "react-router-dom";

export const customerColumn = [
  // {
  //   title: "Sr No.",
  //   key: "srNo",
  //   render: (text, record, index) =>
  //     (currentPage - 1) * entriesPerPage + index + 1,
  //   align: "center",
  // },
  {
    title: "Registered Company",
    dataIndex: "RegisteredCompanyName",
    key: "RegisteredCompanyName",
    sorter: (a, b) =>
      a.RegisteredCompanyName?.localeCompare(b.RegisteredCompanyName),
    align: "center",
  },
  {
    title: "Trading Name",
    dataIndex: "Tradingname",
    key: "Tradingname",
    sorter: (a, b) => a.Tradingname?.localeCompare(b.Tradingname),
    align: "center",
  },
  {
    title: "Email",
    dataIndex: "businessEmail",
    key: "businessEmail",
    sorter: (a, b) => a.businessEmail?.localeCompare(b.businessEmail),
    align: "center",
  },

  {
    title: "Action",
    key: "action",
    render: (text) => (
      <div>
        <span
          onClick={() => {
            message.open({
              type: "loading",
              content: `Wait for the download of ${
                text.type === "customer"
                  ? "Customer Information"
                  : "Pharmacy Registration"
              }`,
            });
            downloadPDF(
              `${api.customerRegistrationsList}/${text._id}/download`,
              `Customer_Registration_${text._id}.pdf`,
              token
            ).finally(() => {
              setTimeout(() => {
                message.open({
                  type: "success",
                  content: "Downloaded Successfully",
                  duration: 2,
                });
              }, 1000);
            });
          }}
          style={{ cursor: "pointer", marginRight: "8px" }}
        >
          <i className="fa fa-file-pdf-o" aria-hidden="true"></i>
        </span>
        <Link to={`/admin/customer/${text._id}`}>
          <i className="fa fa-eye"></i>
        </Link>
      </div>
    ),
    align: "center",
  },
];
