import React, { useState } from "react";
import { toast } from "react-toastify";
import { baseURL } from "../../config/api";
import axios from "axios";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState(""); // State to display messages to the user
  const [loading, setLoading] = useState(false); // State to handle loading state

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true); // Set loading to true when the request starts

    try {
      const response = await axios.post(
        `${baseURL}/api/auth/forgot`,
        {
          email,
        }
      );

      // Assuming the API response contains a message field
      toast.success(response.data.message || "Password reset email sent."); // Show success notification
      setEmail(""); // Clear email field after successful submission
    } catch (error) {
      // Handle errors
      const errorMessage =
        error.response?.data?.message || error.message || "An error occurred";
      toast.error("An error occurred: " + errorMessage); // Show error notification
    } finally {
      setLoading(false); // Reset loading state
    }
  };

  const handleChange = (event) => {
    setEmail(event.target.value);
  };

  return (
    <div>
      <div className="container">
        <div className="row logindiv">
          <div className="col-md-4 col-md-offset-4 text-center logo-margin">
            <div>
              <span className="logotxt1">PRO</span>
              <span className="logotxt2">PHARMA</span>
            </div>
          </div>
          <div className="col-md-4 col-md-offset-4"></div>
          <div className="col-md-4 col-md-offset-4">
            <div className="login-panel panel panel-default">
              <div className="panel-heading">
                <h3 className="panel-title">Forgot Password</h3>
              </div>
              <div className="panel-body">
                <div>
                  Enter your Email below and we'll send you a new
                  password.
                </div>
                <br />
                <form
                  role="form"
                  name="form"
                  id="form"
                  onSubmit={handleSubmit} // Add onSubmit event handler
                >
                  <fieldset>
                    <div className="form-group">
                      <input
                        className="form-control"
                        placeholder="E-mail"
                        name="email"
                        type="email"
                        autoFocus
                        required
                        value={email}
                        onChange={handleChange} // Add onChange event handler
                      />
                    </div>
                    <button
                      type="submit"
                      id="submitbtn"
                      data-text={loading ? "Processing..." : "Get New Password"} // Show loading state
                      className="btn btn-lg btn-success btn-block"
                      disabled={loading} // Disable button during loading
                    >
                      {loading ? "Processing..." : "Get New Password"}
                    </button>
                  </fieldset>
                </form>
                {message && (
                  <div className="alert alert-info mt-3">{message}</div>
                )}{" "}
                {/* Display message */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
