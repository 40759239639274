import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import { api } from "../../config/api";

const LoginForm = () => {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const token = localStorage.getItem("token");

  useEffect(() => {
    if (!token) {
      navigate("/admin");
    } else {
      navigate("/admin/gp-surgery-list");
    }
  }, [token]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!validateEmail(formData.email)) {
      toast.error("Please enter a valid Email.");
      return;
    }
    if (formData.password.length < 6) {
      toast.error("Password must be at least 6 characters long.");
      return;
    }

    try {
      const response = await axios.post(api.login, formData);

      localStorage.setItem("token", response.data.token);
      toast.success("Login successful!");
      navigate("/admin/gp-surgery-list");
    } catch (error) {
      console.error("Error:", error);
      setError("Login failed. Please check your credentials.");
      toast.error("Login failed. Please check your credentials.");
    }
  };

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  return (
    <div>
      <div className="container">
        <div className="logindiv">
          <div className="col-md-4 col-md-offset-4 text-center logo-margin">
            <div>
              <span className="logotxt1">PRO</span>
              <span className="logotxt2">PHARMA</span>
            </div>
          </div>
          <div className="col-md-4 col-md-offset-4"></div>
          <div className="col-md-4 col-md-offset-4">
            <div className="login-panel panel panel-default">
              <div className="panel-heading">
                <h3 className="panel-title">Login to Admin</h3>
              </div>

              <div className="panel-body">
                <form role="form" onSubmit={handleSubmit}>
                  {error && <div className="alert alert-danger">{error}</div>}
                  <input type="hidden" name="action" value="login" />
                  <input
                    type="hidden"
                    name="_token"
                    value="PG5Q7kI5pvAvR66x3VbTGjWKD0xnru1vq3PQxFEn"
                  />
                  <fieldset>
                    <div className="form-group">
                      <input
                        className="form-control"
                        placeholder="Admin E-mail"
                        name="email"
                        type="email"
                        autoFocus
                        required
                        value={formData.email}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="form-group">
                      <input
                        className="form-control"
                        placeholder="Admin Password"
                        name="password"
                        type="password"
                        required
                        value={formData.password}
                        onChange={handleChange}
                      />
                    </div>
                    <button
                      type="submit"
                      id="submitbtn"
                      className="btn btn-lg btn-success btn-block"
                    >
                      Login
                    </button>
                    <br />

                    <Link to="/admin/forgot_password">
                      Forgot your password?
                    </Link>
                  </fieldset>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginForm;
