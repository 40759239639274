import React, { useEffect, useState } from "react";
import Sidebar from "../Sidebar";
import { ConfigProvider, Table } from "antd";
import axios from "axios";
import { api } from "../../../../config/api";
import { token } from "../../../../config/globleData";
import Heading from "../../../../globleComponents/Heading";
import useDebounce from "../../../../config/useDebounce";
import SearchInput from "../../../../globleComponents/SearchInput";
import { customerColumn } from "./columns/customerColumn";

const CustomerList = () => {
  const [customersData, setCustomersData] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [entriesPerPage, setEntriesPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalData, setTotalData] = useState(0);
  const [loading, setLoading] = useState(false);
  const debouncedSearchTerm = useDebounce(searchTerm, 1000); // 1 second delay

  const fetchCustomersData = async (
    page = currentPage,
    limit = entriesPerPage
  ) => {
    setLoading(true);
    try {
      const response = await axios.get(api.customerRegistrationsList, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          startDate,
          endDate,
          page,
          limit,
          searchTerm,
        },
      });
      setCustomersData(response?.data?.customerRegistrations);
      setTotalData(response.data.totalRegistrations);
    } catch (error) {
      console.error("Error fetching customers data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCustomersData(currentPage, entriesPerPage);
  }, [startDate, endDate, currentPage, entriesPerPage, debouncedSearchTerm]);

  const filteredData = customersData.filter((customer) => {
    // const customerDate = moment(customer.registrationDate, "YYYY-MM-DD");
    // const start = startDate ? moment(startDate) : null;
    // const end = endDate ? moment(endDate) : null;
    // const isInRange =
    //   (!startDate || customerDate.isSameOrAfter(start, "day")) &&
    //   (!endDate || customerDate.isSameOrBefore(end, "day"));
    const matchesSearch =
      customer.RegisteredCompanyName?.toLowerCase()?.includes(
        searchTerm?.toLowerCase()
      ) ||
      customer.Tradingname?.toLowerCase()?.includes(
        searchTerm?.toLowerCase()
      ) ||
      customer.businessEmail
        ?.toLowerCase()
        ?.includes(searchTerm?.toLowerCase());

    return matchesSearch;
  });

  return (
    <div
      className="dashboard-wrapper"
      style={{
        display: "flex",
        height: "100vh",
        overflow: "hidden",
      }}
    >
      <Sidebar />
      <div
        className="content-wrapper"
        style={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          overflow: "auto",
        }}
      >
        <Heading heading="Customer Collection" />
        <div className="searchproj">
          <div className="row">
            <div className="col-md-9 d-flex justify-content-start">
              <div id="example_filter" className="dataTables_filter">
                <label className="list_label">
                  <SearchInput
                    searchTerm={searchTerm}
                    setSearchTerm={setSearchTerm}
                  />
                </label>
              </div>
            </div>
          </div>
        </div>
        <div
          className="datalist projectlist"
          style={{
            flex: 1,
            overflow: "auto",
          }}
        >
          <div className="table-responsive">
            <ConfigProvider
              theme={{
                components: {
                  Table: {
                    headerBg: "#F3F4F7",
                    headerBorderRadius: "4px",
                    fontFamily:"Blogger Sans",
                  },
                },
              }}
            >
              <Table
                className="custom-table"
                size="middle"
                columns={customerColumn}
                dataSource={customersData}
                pagination={{
                  size: "default",
                  pageSizeOptions: ["10", "25", "50", "100"],
                  showSizeChanger: true,
                  current: currentPage,
                  pageSize: entriesPerPage,
                  total: totalData,
                  onChange: (page, pageSize) => {
                    setCurrentPage(page);
                    setEntriesPerPage(pageSize);
                  },
                }}
                loading={loading}
                bordered
                rowKey={(record) => record._id}
              />
            </ConfigProvider>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomerList;
