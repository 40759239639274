import React, { useEffect, useState } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';

const ProtectedRoute = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(null); // null indicates loading
  const token = localStorage.getItem('token');
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const checkAuth = async () => {
      // Simulate async token validation if needed
      if (token) {
        setIsAuthenticated(true);
      } else {
        setIsAuthenticated(false);
      }
    };

    checkAuth();
  }, [token]);

  useEffect(() => {
    if (isAuthenticated === false) {
      navigate('/admin', { state: { from: location }, replace: true });
    }
  }, [isAuthenticated, navigate, location]);

  // if (isAuthenticated === null) {
  //   return <div>Loading...</div>; // Or a spinner/loading component
  // }

  return isAuthenticated ? children : null;
};

export default ProtectedRoute;
