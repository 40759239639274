import React, { useEffect, useState } from "react";
import Sidebar from "../Sidebar";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { api } from "../../../../config/api";
import { formatDate, token } from "../../../../config/globleData";
import { Button } from "antd";
import {
  contentWrapperStyle,
  dashboardWrapperStyle,
  sidebarStyle,
} from "../../../../config/style";

const ViewRegister = () => {
  const { id } = useParams();
  const [registerData, setRegisterData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${api.GPregistrationsList}/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setRegisterData(response.data);
      } catch (error) {
        setError("Failed to fetch register data");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [id]);

  return (
    <div style={dashboardWrapperStyle}>
      <Sidebar style={sidebarStyle} />
      <div style={contentWrapperStyle}>
        <main role="main" className="col-md-12 ms-sm-auto col-lg-12 px-4">
          <div
            className="heading"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              margin: "0px 0px 50px -29px",
              width: "100%",
              
            }}
          >
            <span>GP Surgery Register Details</span>
            <button
              type="button"
              className="btn_back"
              onClick={() => navigate(-1)}
            
            >
              Back to List
            </button>
          </div>

          <div
            className="card mb-4 details-card border-0"
            style={{ marginTop: "0px" }}
          >
            {/* <div className="card-header bg-success text-white rounded-bottom">
              <h2 className="card-title mb-0">
                <u>GP Surgery Register Details</u>
              </h2>
            </div> */}
            <div className="card-body">
              <div className="row mb-3">
                <div className="col-md-6">
                  <h5>
                    Surgery Name:{" "}
                    <span className="text-success">
                      {registerData?.surgeryName}
                    </span>
                  </h5>
                </div>
                <div className="col-md-6">
                  <h5>
                    Type:{" "}
                    <span className="text-info">{registerData?.type}</span>
                  </h5>
                </div>
              </div>

              <div className="row mb-4">
                <div className="col-12">
                  <h4 className="text-success">Company Details</h4>
                  <table className="table table-bordered table-striped details-table">
                    <tbody>
                      <tr>
                        <td>
                          <strong>Address:</strong>
                        </td>
                        <td>{registerData?.Address1}</td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Postcode:</strong>
                        </td>
                        <td>{registerData?.Postcode}</td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Owned By:</strong>
                        </td>
                        <td>{registerData?.ownedBy}</td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Trading As:</strong>
                        </td>
                        <td>{registerData?.tradingAs}</td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Website URL:</strong>
                        </td>
                        <td>{registerData?.websiteURL}</td>
                      </tr>
                      <tr>
                        <td>
                          <strong>VAT No:</strong>
                        </td>
                        <td>{registerData?.vatNo}</td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Company Registration No:</strong>
                        </td>
                        <td>{registerData?.companyRegistrationNo}</td>
                      </tr>
                      <tr>
                        <td>
                          <strong>CQC Registration Name:</strong>
                        </td>
                        <td>{registerData?.CQCRegistrationName}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <div className="row mb-4">
                <div className="col-12">
                  <h4 className="text-success">Contact Details</h4>
                  <table className="table table-bordered table-striped details-table">
                    <tbody>
                      <tr>
                        <td>
                          <strong>Contact Person:</strong>
                        </td>
                        <td>{registerData?.contactPerson}</td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Contact Number:</strong>
                        </td>
                        <td>{registerData?.contactNumber}</td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Contact Email:</strong>
                        </td>
                        <td>{registerData?.contactEmail}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <div className="row mb-4">
                <div className="col-12">
                  <h4 className="text-success">Professionals</h4>
                  {registerData?.professionals?.length ? (
                    <table className="table table-bordered table-striped details-table">
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>Authority</th>
                        </tr>
                      </thead>
                      <tbody>
                        {registerData.professionals.map((prof, index) => (
                          <tr key={index}>
                            <td>{prof.name}</td>
                            <td>{prof.authority}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  ) : (
                    <p className="text-muted">No professionals listed.</p>
                  )}
                </div>
              </div>

              <div className="row mb-4">
                <div className="col-12">
                  <h4 className="text-success">Accounts Information</h4>
                  <table className="table table-bordered table-striped details-table">
                    <tbody>
                      <tr>
                        <td>
                          <strong>Accounts Email:</strong>
                        </td>
                        <td>{registerData?.accountsEmail}</td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Accounts Contact Person:</strong>
                        </td>
                        <td>{registerData?.accountsContactPerson}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <div className="row mb-4">
                <div className="col-12">
                  <h4 className="text-success">Additional Information</h4>
                  <table className="table table-bordered table-striped details-table">
                    <tbody>
                      <tr>
                        <td>
                          <strong>Form Completed By:</strong>
                        </td>
                        <td>{registerData?.formCompletedBy}</td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Completion Date:</strong>
                        </td>
                        <td>{formatDate(registerData?.completionDate)}</td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Comment:</strong>
                        </td>
                        <td>{registerData?.comment}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default ViewRegister;
