import { message } from "antd";
import {
  downloadPDF,
  formatDate,
  token,
} from "../../../../../config/globleData";
import { api } from "../../../../../config/api";
import { Link } from "react-router-dom";

export const gpSurgeryColumn = [
  // {
  //   title: "Sr No.",
  //   key: "srNo",
  //   render: (text, record, index) =>
  //     (currentPage - 1) * entriesPerPage + index + 1,
  //   align: "center",
  // },
  {
    title: "Surgery Name",
    dataIndex: "surgeryName",
    key: "surgeryName",
    render: (text, record) => (
      <div>
        <Link style={{ color: "#408dfb" }} to={`/admin/gp-surgery-list/${record._id}`}>
          {record.surgeryName}
        </Link>
      </div>
    ),
    sorter: (a, b) => a.surgeryName?.localeCompare(b.surgeryName),
    align: "center",
  },
  {
    title: "Trading Name",
    dataIndex: "tradingAs",
    key: "tradingAs",
    sorter: (a, b) => a.tradingAs?.localeCompare(b.tradingAs),
    align: "center",
  },
  {
    title: "Email",
    dataIndex: "contactEmail",
    key: "contactEmail",
    sorter: (a, b) => a.contactEmail?.localeCompare(b.contactEmail),
    align: "center",
  },
  {
    title: "Completion Date",
    key: "completionDate",
    render: (text, record) => <div>{formatDate(record.completionDate)}</div>,
    sorter: (a, b) => new Date(a.completionDate) - new Date(b.completionDate),
    align: "center",
  },
  {
    title: "Action",
    key: "action",
    render: (text) => (
      <div>
        <span
          onClick={() => {
            message.open({
              type: "loading",
              content: "Wait for the download of GP Surgery Registration",
            });
            downloadPDF(
              `${api.GPregistrationsList}/${text._id}/download`,
              `GP_Surgery_Registration_${text._id}.pdf`,
              token
            ).finally(() => {
              setTimeout(() => {
                message.open({
                  type: "success",
                  content: "Downloaded Successfully",
                  duration: 1,
                });
              }, 1000);
            });
          }}
          style={{ cursor: "pointer", marginRight: "8px" }}
        >
          <span style={{ color: "#408dfb" }}>Download</span>
          {/* <i className="fa fa-file-pdf-o" aria-hidden="true"></i> */}
        </span>
        {/* <Link to={`/admin/gp-surgery-list/${text._id}`}>
          <i className="fa fa-eye"></i>
        </Link> */}
      </div>
    ),
    align: "center",
  },
];
