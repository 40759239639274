import React, { useEffect, useState } from "react";
import Sidebar from "../Sidebar";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { api, baseURL } from "../../../../config/api";
import { token } from "../../../../config/globleData";
import { Button } from "antd";
import {
  contentWrapperStyle,
  dashboardWrapperStyle,
  sidebarStyle,
} from "../../../../config/style";

const ViewPharmacyRegister = () => {
  const { id } = useParams();
  const [pharmacyData, setPharmacyData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [certificates, setCertificates] = useState({
    temperatureRecordDraft: null,
  });
  const navigate = useNavigate();

  const getFileByType = async (id, type) => {
    try {
      const response = await axios.get(
        `${baseURL}/api/registrations/registration/${id}/file/${type}`,
        {
          responseType: "blob",
        }
      );
      return response;
    } catch (error) {
      console.error("Error fetching file:", error);
      throw error;
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch pharmacy data
        const response = await axios.get(
          `${api.pharmacyRegistrationsList}/${id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setPharmacyData(response.data);

        // Fetch certificates if available
        const [tempResponse] = await Promise.all([
          getFileByType(id, "temperatureRecordDraft"),
        ]);

        setCertificates({
          temperatureRecordDraft: URL.createObjectURL(tempResponse.data),
        });
      } catch (error) {
        setError("Failed to fetch pharmacy data or certificates");
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [id]);

  return (
    <div style={dashboardWrapperStyle}>
      <Sidebar style={sidebarStyle} />
      <div style={contentWrapperStyle}>
        <main role="main" className="col-md-9 ms-sm-auto col-lg-12 px-4">
          <div
            className="heading"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              margin: "0px 0px 50px -29px",
              width: "100%",
            }}
          >
            <span> Pharmacy Register Details</span>
            <button
              type="button"
              className="btn_back"
              onClick={() => navigate(-1)}
              style={{ marginRight: "0px" }}
            >
              Back to List
            </button>
          </div>

          <div
            className="card mb-4 details-card border-0"
            style={{ marginTop: "0px" }}
          >
            {/* <div className="card-header bg-success text-white">
              <h2 className="card-title mb-0">
                <u>Pharmacy Register Details</u>
              </h2>
            </div> */}
            <div className="card-body">
              {/* Company Details */}
              <div className="row mb-4">
                <div className="col-12">
                  <h4 className="text-success">Company Details</h4>
                  <table className="table table-bordered table-striped details-table">
                    <tbody>
                      <tr>
                        <td>
                          <strong>Registered Company Name:</strong>
                        </td>
                        <td>{pharmacyData?.RegisteredCompanyName}</td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Trading Name:</strong>
                        </td>
                        <td>{pharmacyData?.Tradingname}</td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Address 1:</strong>
                        </td>
                        <td>{pharmacyData?.Address1}</td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Address 2:</strong>
                        </td>
                        <td>{pharmacyData?.Address2}</td>
                      </tr>
                      <tr>
                        <td>
                          <strong>City:</strong>
                        </td>
                        <td>{pharmacyData?.City}</td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Postcode:</strong>
                        </td>
                        <td>{pharmacyData?.Postcode}</td>
                      </tr>
                      <tr>
                        <td>
                          <strong>VAT No:</strong>
                        </td>
                        <td>{pharmacyData?.vatNo}</td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Company Registration No:</strong>
                        </td>
                        <td>{pharmacyData?.companyRegistrationNo}</td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Website URL:</strong>
                        </td>
                        <td>{pharmacyData?.websiteURL}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              {/* Contact Details */}
              <div className="row mb-4">
                <div className="col-12">
                  <h4 className="text-success">Contact Details</h4>
                  <table className="table table-bordered table-striped details-table">
                    <tbody>
                      <tr>
                        <td>
                          <strong>Business Email:</strong>
                        </td>
                        <td>{pharmacyData?.businessEmail}</td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Business Telephone No:</strong>
                        </td>
                        <td>{pharmacyData?.businessTelephoneNo}</td>
                      </tr>

                      {pharmacyData?.ApplicationFaxNo && (
                        <tr>
                          <td>
                            <strong>Application Fax No:</strong>
                          </td>
                          <td>{pharmacyData?.ApplicationFaxNo}</td>
                        </tr>
                      )}
                      {pharmacyData?.ApplicantTelephoneNo && (
                        <tr>
                          <td>
                            <strong>Applicant Telephone No:</strong>
                          </td>
                          <td>{pharmacyData?.ApplicantTelephoneNo}</td>
                        </tr>
                      )}
                      {pharmacyData?.DeliveryPrimaryContactNo && (
                        <tr>
                          <td>
                            <strong>Delivery Primary Contact No:</strong>
                          </td>
                          <td>{pharmacyData?.DeliveryPrimaryContactNo}</td>
                        </tr>
                      )}
                      {pharmacyData?.CollectionPrimaryContactNo && (
                        <tr>
                          <td>
                            <strong>Collection Primary Contact No:</strong>
                          </td>
                          <td>{pharmacyData?.CollectionPrimaryContactNo}</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>

              {/* Directors & Shareholders */}
              <div className="row mb-4">
                <div className="col-12">
                  <h4 className="text-success">Directors & Shareholders</h4>
                  <table className="table table-bordered table-striped details-table">
                    <tbody>
                      <tr>
                        <td>
                          <strong>Directors:</strong>
                        </td>
                        <td>{pharmacyData?.directors.join(", ")}</td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Shareholders:</strong>
                        </td>
                        <td>{pharmacyData?.shareholders.join(", ")}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              {/* Pharmacists Details */}
              <div className="row mb-4">
                <div className="col-12">
                  <h4 className="text-success">Pharmacists Details</h4>
                  <table className="table table-bordered table-striped details-table">
                    <tbody>
                      <tr>
                        <td>
                          <strong>Superintendent Pharmacist:</strong>
                        </td>
                        <td>{pharmacyData?.superintendentPharmacistName}</td>
                      </tr>
                      <tr>
                        <td>
                          <strong>GPHC Number:</strong>
                        </td>
                        <td>
                          {pharmacyData?.superintendentPharmacistGPHCNumber}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Resident Pharmacist:</strong>
                        </td>
                        <td>{pharmacyData?.residentPharmacistName}</td>
                      </tr>
                      <tr>
                        <td>
                          <strong>GPHC Number:</strong>
                        </td>
                        <td>{pharmacyData?.residentPharmacistGPHCNumber}</td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Resident Pharmacist Email:</strong>
                        </td>
                        <td>{pharmacyData?.residentPharmacistEmail}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              {/* Additional Information */}
              <div className="row mb-4">
                <div className="col-12">
                  <h4 className="text-success">Additional Information</h4>
                  <table className="table table-bordered table-striped details-table">
                    <tbody>
                      <tr>
                        <td>
                          <strong>Business Account Contact Department:</strong>
                        </td>
                        <td>{pharmacyData?.businessAccountContactDept}</td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Signature:</strong>
                        </td>
                        <td>{pharmacyData?.signature}</td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Signature Email:</strong>
                        </td>
                        <td>{pharmacyData?.signatureEmailAddress}</td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Company Registration No.:</strong>
                        </td>
                        <td>{pharmacyData?.companyRegistrationNo}</td>
                      </tr>
                      {/* <tr>
                        <td>
                          <strong>Company Registration Address:</strong>
                        </td>
                        <td>{pharmacyData?.companyRegistrationAddress}</td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Company Telephone Number:</strong>
                        </td>
                        <td>{pharmacyData?.companyTelephoneNumber}</td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Company Email:</strong>
                        </td>
                        <td>{pharmacyData?.companyEmailAddress}</td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Date of Registration:</strong>
                        </td>
                        <td>{formatDate(pharmacyData?.dateOfRegistration)}</td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Updated At:</strong>
                        </td>
                        <td>{formatDate(pharmacyData?.updatedAt)}</td>
                      </tr> */}
                    </tbody>
                  </table>
                </div>
              </div>

              <div className="row mb-4">
                <div className="col-12">
                  <h4 className="text-success">Certificates</h4>
                  <table className="table table-bordered table-striped details-table">
                    <tbody>
                      <tr>
                        <td>
                          <strong>Temperature Record Draft:</strong>
                        </td>
                        <td>
                          {pharmacyData?.files?.temperatureRecordDraft && (
                            <div>
                              <a
                                href={
                                  pharmacyData?.files?.temperatureRecordDraft
                                }
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                View Temperature Record Draft
                              </a>
                            </div>
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default ViewPharmacyRegister;
